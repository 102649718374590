import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/FoundersInfo.module.css'

// export default function FoundersInfo() {
//   return (
//     <>
//     {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link> */}
//     <h1 className={styles.founders_title}>Founders</h1>
//     <div>
//     <div className={styles.founderspage}>
//     <div className={styles.card}>
//   <Image src="/assets/vaibhavs_with_tv_logo.png" alt="Vercel Logo"
//     height={250}
//     width={300} 
//     className={styles.tvlogo}
//       />
//   <h1>Vaibhav Shenoy</h1>
//   <p className={styles.title}>Founder & CEO</p>
//   <p>Hare Krishna To Everyone! </p>
//   <p>"I am Vaibhav Shenoy Founder & CEO of Threed Verse .Threed Verse is a NextGen Company Which will be Revolutionizing the way we interact with almost everything and will lead the Next Generation of amazing experiences awaiting us.Our Vision is to create an Immersive Internet and products that touch every humans heart and enables and empowers them to lead the best version of their life"</p>
//    <a href="https://www.linkedin.com/in/vaibhav-shenoy-653a31267/" target="_blank" rel="noopener noreferrer"><Image src="/assets/linkedin.png" alt="Vercel Logo"
//     height={50}
//     width={50} 
//       /></a>
// </div>
// <div></div>
// </div>
// <div className={styles.founderspage}>
//     <div className={styles.card}>
//   <Image src="/assets/mummy_two.png" alt="Vercel Logo"
//     height={250}
//     width={250} 
//     className={styles.tvlogo}
//       />
//   <h1>Kshamata Shenoy</h1>
//   <p className={styles.title}>CoFounder & COO</p>
//   <p>Hare Krishna To Everyone! </p>
//   <p>"I am Kshamata Shenoy CoFounder & COO at Threed Verse .At Threed Verse we are using Deep Tech to solve world most pressing problems in the most innovative,creative and amazing way that would bring a positive impact to this World" </p>
//    <a href="https://www.linkedin.com/in/kshamata-shenoy-10765978/" target="_blank" rel="noopener noreferrer"><Image src="/assets/linkedin.png" alt="Vercel Logo"
//     height={50}
//     width={50} 
//       /></a>
// </div>
// <div></div>
// </div>
// </div>
//     </>
//   )
// }


export default function FoundersInfo() {
  return (
    <>
    {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link> */}
    <h1 className={styles.founders_title}>Founders</h1>
    <div>
    <div className={styles.founderspage}>
    <div className={styles.card}>
  {/* <Image src="/assets/vaibhavs_with_tv_logo.png" alt="Vercel Logo"
    height={250}
    width={300} 
    className={styles.tvlogo}
      /> */}
      <img src="./assets/vaibhavs_with_tv_logo.png"  height={250}
    width={300} 
    className={styles.tvlogo}></img>
  <h1 className={styles.founders_name}>Vaibhav Shenoy</h1>
  <p className={styles.founders_internal_title}>CoFounder & CEO</p>
  <p className={styles.harekrishna}>Hare Krishna To Everyone! </p>
  <p className={styles.foundersinfo}>&quot;I am Vaibhav Shenoy Founder & CEO of Threed Verse. Threed Verse is a NextGen Company Which will be Revolutionizing the way we interact with almost everything and will lead the Next Generation of amazing experiences awaiting us.Our Vision is to create an Immersive Internet and products that touch every humans heart and enables and empowers them to lead the best version of their life&quot;</p>
   <a href="https://www.linkedin.com/in/vaibhav-shenoy-653a31267/" target="_blank" rel="noopener noreferrer">
    {/* <Image src="/assets/linkedin.png" alt="Vercel Logo"
    height={50}
    width={50} 
      /> */}
      <img src="./assets/linkedin.png"     height={50}
    width={50} ></img>
      </a>
</div>
<div></div>
</div>
<div className={styles.founderspage}>
    <div className={styles.card}>
  {/* <Image src="/assets/mummy_two.png" alt="Vercel Logo"
    height={250}
    width={250} 
    className={styles.tvlogo}
      /> */}
      <img src="./assets/mummy_two.png"     height={250}
    width={250} 
    className={styles.tvlogo}></img>
  <h1 className={styles.founders_name}>Kshamata Shenoy</h1>
  <p className={styles.founders_internal_title}>CoFounder & COO</p>
  <p className={styles.harekrishna}>Hare Krishna To Everyone! </p>
  <p className={styles.foundersinfo}>&quot;I am Kshamata Shenoy CoFounder & COO at Threed Verse. At Threed Verse we are using Deep Tech to solve world most pressing problems in the most innovative,creative and amazing way that would bring a positive impact to this World&quot; </p>
   <a href="https://www.linkedin.com/in/kshamata-shenoy-10765978/" target="_blank" rel="noopener noreferrer">
    {/* <Image src="/assets/linkedin.png" alt="Vercel Logo"
    height={50}
    width={50} 
      /></a> */}
        <img src="./assets/linkedin.png"     height={50}
    width={50} ></img>
    </a>
</div>
<div></div>
</div>
</div>
    </>
  )
}
