import React from 'react'
import styles from './componentstyles/AboutUs.module.css'
// '../../styles/AboutUs.module.css'
import { lazy,Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { Center, Environment } from '@react-three/drei'
// import ArGlasses  from './THREEDCOMPONENTS/ArGlasses'

const ArGlasses = lazy(() => import("./THREEDCOMPONENTS/ArGlasses"));

export default function AboutUs() {
  return (
    <>
    <div className={styles.aboutusscreencontainer}>
    <h1 className={styles.strokedaboutus}>About Us</h1>
    <div className={styles.threedtlogocontainer}>
          {/* <Canvas style={{backgroundColor:"black"}}> */}
          <Canvas style={{paddingTop:"0px" ,width: "100vw", height: "50vh"}}>
            <Center/>
        <ambientLight intensity={0.1} />
  <directionalLight color="white" position={[0, 0, 5]} />
        <Suspense fallback={null}>
          <ArGlasses />
          {/* <Environment preset="sunset" background /> */}
        </Suspense>
      </Canvas>
         
      </div>
    <h1 className={styles.revolutioncoming}>#There is a Revolution Coming</h1>
    <h1 className={styles.heretomake}>&quot;We Are Here To Make A</h1>
    <h1 className={styles.trilliondollarimpact}>1000000000000 Impact&quot;</h1>
    <h1 className={styles.commitment}>A Commitment of Trillion Dollar Impact in Next 5 To 10 Years</h1>
    <h1 className={styles.actualabout}>&quot;Threed Verse is a NextGeneration Deep Tech and WEB 3 Startup,With a Vision to build an Immersive Internet.&quot;</h1>
    <h1 className={styles.actualabout}>&quot;We are doing what is needed to be done! &quot;</h1>
    <h1 className={styles.actualabout}>&quot;The Immersive Experience that we are building will give an unparalled experience to our end Users&quot;</h1>
    <h1 className={styles.lasttagline}>&quot;We are making experiences remarkable,lovable and enjoyable.&quot;</h1>
    </div>
    </>
  )
}
