import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/InvestIn.module.css'

export default function InvestIn() {
  return (
    <>
    <div className={styles.investinscreencontainer}>
    <h1 className={styles.strokedinvestin}>Invest In ThreedVerse</h1>
    <h1 className={styles.welcometo}>Invest in our Vision of building an &quot;Immersive Internet&quot;</h1>
    <h1 className={styles.welcometo}>&quot;Help Us Shape The World To Empower everyone to Lead the Best Version Of Their Life&quot;</h1>
    <h1 className={styles.welcometo}>&quot;To Invest Contact Us at&quot;</h1>
    <h1 className={styles.investin}>&quot;investinthreedverse@gmail.com&quot;</h1>
    </div>
    </>
  )
}
