import React from 'react'
import styles from './componentstyles/OurBelief.module.css'
// import styles from '../../styles/Hscreen.module.css'

export default function OurBelief() {
  return (
    <>
    {/* <div className={styles.aboutusscreencontainer}>
    <h1 className={styles.welcometo}>Our Belief</h1>
    <h1 className={styles.attv}>At Threed Verse</h1>
    <h1 className={styles.ourbelief}>Our Belief is</h1>
    <h1 className={styles.empowering}>#Empowering Incredible Experiences</h1>
    </div> */}
      {/* <div className={styles.hscreencontainer}>
    <h1 className={styles.newweat}>Our Belief</h1>
    <div className={styles.wrapper}>
  <div className={styles.container}>
    <h1 className={styles.multicolouredhone}>At Threed Verse Our Belief is </h1>
    <h1 className={styles.multicolouredhone}>#Empowering Incredible Experiences</h1>
  </div>
</div>
    </div> */}
        <div className={styles.aboutusscreencontainer}>
    <h1 className={styles.newourbelief}>Our Belief</h1>
    <h1 className={styles.attv}>At Threed Verse</h1>
    <h1 className={styles.ourbelief}>Our Belief is</h1>
    <h1 className={styles.empowering}>#Empowering Incredible Experiences</h1>
    </div>
    </>
  )
}
