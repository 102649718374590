import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function TvLogoCapsD(props) {
  const { nodes, materials } = useGLTF("/assets/newthreedversecapitalDtestone.glb");
  return (
    // <group {...props} dispose={null}>
    //   <group
    //   //original
    //     // position={[-7.87, 0, 0]}
    //     // rotation={[Math.PI / 2, 0, 0]}
    //     // scale={35.33}
    //     //using old dimension
    //     // position={[-5, -1, 0]}
    //     position={[-5.01, -1, 0]}
    //     rotation={[1.65, 0, -0.1]}
    //     // scale={12.9}
    //     scale={13}
    //   >
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_1.geometry}
    //       material={materials["SVGMat.086"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_2.geometry}
    //       material={materials["SVGMat.002"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_3.geometry}
    //       material={materials["SVGMat.003"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_4.geometry}
    //       material={materials["SVGMat.004"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_5.geometry}
    //       material={materials["SVGMat.005"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_6.geometry}
    //       material={materials["SVGMat.006"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_7.geometry}
    //       material={materials["SVGMat.007"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_8.geometry}
    //       material={materials["SVGMat.008"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_9.geometry}
    //       material={materials["SVGMat.009"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_10.geometry}
    //       material={materials["SVGMat.010"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_11.geometry}
    //       material={materials["SVGMat.011"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_12.geometry}
    //       material={materials["SVGMat.012"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_13.geometry}
    //       material={materials["SVGMat.013"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_14.geometry}
    //       material={materials["SVGMat.014"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_15.geometry}
    //       material={materials["SVGMat.015"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_16.geometry}
    //       material={materials["SVGMat.016"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_17.geometry}
    //       material={materials["SVGMat.017"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_18.geometry}
    //       material={materials["SVGMat.018"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_19.geometry}
    //       material={materials["SVGMat.019"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_20.geometry}
    //       material={materials["SVGMat.020"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_21.geometry}
    //       material={materials["SVGMat.021"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_22.geometry}
    //       material={materials["SVGMat.022"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_23.geometry}
    //       material={materials["SVGMat.023"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_24.geometry}
    //       material={materials["SVGMat.024"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_25.geometry}
    //       material={materials["SVGMat.025"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_26.geometry}
    //       material={materials["SVGMat.026"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_27.geometry}
    //       material={materials["SVGMat.027"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_28.geometry}
    //       material={materials["SVGMat.028"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_29.geometry}
    //       material={materials["SVGMat.029"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_30.geometry}
    //       material={materials["SVGMat.030"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_31.geometry}
    //       material={materials["SVGMat.031"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_32.geometry}
    //       material={materials["SVGMat.032"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_33.geometry}
    //       material={materials["SVGMat.033"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_34.geometry}
    //       material={materials["SVGMat.034"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_35.geometry}
    //       material={materials["SVGMat.035"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_36.geometry}
    //       material={materials["SVGMat.036"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_37.geometry}
    //       material={materials["SVGMat.037"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_38.geometry}
    //       material={materials["SVGMat.038"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_39.geometry}
    //       material={materials["SVGMat.039"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_40.geometry}
    //       material={materials["SVGMat.040"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_41.geometry}
    //       material={materials["SVGMat.041"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_42.geometry}
    //       material={materials["SVGMat.042"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_43.geometry}
    //       material={materials["SVGMat.043"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_44.geometry}
    //       material={materials["SVGMat.044"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_45.geometry}
    //       material={materials["SVGMat.045"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_46.geometry}
    //       material={materials["SVGMat.046"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_47.geometry}
    //       material={materials["SVGMat.047"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_48.geometry}
    //       material={materials["SVGMat.048"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_49.geometry}
    //       material={materials["SVGMat.049"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_50.geometry}
    //       material={materials["SVGMat.050"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_51.geometry}
    //       material={materials["SVGMat.051"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_52.geometry}
    //       material={materials["SVGMat.052"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_53.geometry}
    //       material={materials["SVGMat.053"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_54.geometry}
    //       material={materials["SVGMat.054"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_55.geometry}
    //       material={materials["SVGMat.055"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_56.geometry}
    //       material={materials["SVGMat.056"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_57.geometry}
    //       material={materials["SVGMat.057"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_58.geometry}
    //       material={materials["SVGMat.058"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_59.geometry}
    //       material={materials["SVGMat.059"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_60.geometry}
    //       material={materials["SVGMat.060"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_61.geometry}
    //       material={materials["SVGMat.061"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_62.geometry}
    //       material={materials["SVGMat.062"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_63.geometry}
    //       material={materials["SVGMat.063"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_64.geometry}
    //       material={materials["SVGMat.064"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_65.geometry}
    //       material={materials["SVGMat.065"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_66.geometry}
    //       material={materials["SVGMat.066"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_67.geometry}
    //       material={materials["SVGMat.067"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_68.geometry}
    //       material={materials["SVGMat.068"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_69.geometry}
    //       material={materials["SVGMat.069"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_70.geometry}
    //       material={materials["SVGMat.070"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_71.geometry}
    //       material={materials["SVGMat.071"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_72.geometry}
    //       material={materials["SVGMat.072"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_73.geometry}
    //       material={materials["SVGMat.073"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_74.geometry}
    //       material={materials["SVGMat.074"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_75.geometry}
    //       material={materials["SVGMat.075"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_76.geometry}
    //       material={materials["SVGMat.076"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_77.geometry}
    //       material={materials["SVGMat.077"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_78.geometry}
    //       material={materials["SVGMat.078"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_79.geometry}
    //       material={materials["SVGMat.079"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_80.geometry}
    //       material={materials["SVGMat.080"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_81.geometry}
    //       material={materials["SVGMat.081"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_82.geometry}
    //       material={materials["SVGMat.082"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_83.geometry}
    //       material={materials["SVGMat.083"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_84.geometry}
    //       material={materials["SVGMat.084"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_85.geometry}
    //       material={materials["SVGMat.085"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_86.geometry}
    //       material={materials["SVGMat.087"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_87.geometry}
    //       material={materials["SVGMat.088"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_88.geometry}
    //       material={materials["SVGMat.089"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_89.geometry}
    //       material={materials["SVGMat.090"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_90.geometry}
    //       material={materials["SVGMat.091"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_91.geometry}
    //       material={materials["SVGMat.092"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_92.geometry}
    //       material={materials["SVGMat.093"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_93.geometry}
    //       material={materials["SVGMat.094"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_94.geometry}
    //       material={materials["SVGMat.095"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_95.geometry}
    //       material={materials["SVGMat.096"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_96.geometry}
    //       material={materials["SVGMat.097"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_97.geometry}
    //       material={materials["SVGMat.098"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_98.geometry}
    //       material={materials["SVGMat.099"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_99.geometry}
    //       material={materials["SVGMat.100"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_100.geometry}
    //       material={materials["SVGMat.101"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_101.geometry}
    //       material={materials["SVGMat.102"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_102.geometry}
    //       material={materials["SVGMat.103"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_103.geometry}
    //       material={materials["SVGMat.104"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_104.geometry}
    //       material={materials["SVGMat.105"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_105.geometry}
    //       material={materials["SVGMat.106"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_106.geometry}
    //       material={materials["SVGMat.107"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_107.geometry}
    //       material={materials["SVGMat.108"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_108.geometry}
    //       material={materials["SVGMat.109"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_109.geometry}
    //       material={materials["SVGMat.110"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_110.geometry}
    //       material={materials["SVGMat.111"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_111.geometry}
    //       material={materials["SVGMat.112"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_112.geometry}
    //       material={materials["SVGMat.113"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_113.geometry}
    //       material={materials["SVGMat.114"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_114.geometry}
    //       material={materials["SVGMat.115"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_115.geometry}
    //       material={materials["SVGMat.116"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_116.geometry}
    //       material={materials["SVGMat.117"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_117.geometry}
    //       material={materials["SVGMat.118"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_118.geometry}
    //       material={materials["SVGMat.119"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_119.geometry}
    //       material={materials["SVGMat.120"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_120.geometry}
    //       material={materials["SVGMat.121"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_121.geometry}
    //       material={materials["SVGMat.122"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_122.geometry}
    //       material={materials["SVGMat.123"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_123.geometry}
    //       material={materials["SVGMat.124"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_124.geometry}
    //       material={materials["SVGMat.125"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_125.geometry}
    //       material={materials["SVGMat.126"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_126.geometry}
    //       material={materials["SVGMat.127"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_127.geometry}
    //       material={materials["SVGMat.128"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_128.geometry}
    //       material={materials["SVGMat.129"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_129.geometry}
    //       material={materials["SVGMat.130"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_130.geometry}
    //       material={materials["SVGMat.131"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_131.geometry}
    //       material={materials["SVGMat.132"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_132.geometry}
    //       material={materials["SVGMat.133"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_133.geometry}
    //       material={materials["SVGMat.134"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_134.geometry}
    //       material={materials["SVGMat.135"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_135.geometry}
    //       material={materials["SVGMat.136"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_136.geometry}
    //       material={materials["SVGMat.137"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_137.geometry}
    //       material={materials["SVGMat.138"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_138.geometry}
    //       material={materials["SVGMat.139"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_139.geometry}
    //       material={materials["SVGMat.140"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_140.geometry}
    //       material={materials["SVGMat.142"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_141.geometry}
    //       material={materials["SVGMat.155"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_142.geometry}
    //       material={materials["SVGMat.156"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_143.geometry}
    //       material={materials["SVGMat.157"]}
    //     />
    //     <mesh
    //       castShadow
    //       receiveShadow
    //       geometry={nodes.Curve085_144.geometry}
    //       material={materials["SVGMat.158"]}
    //     />
    //   </group>
    // </group>
    <group {...props} dispose={null}>
    <group
//       position={[-0.13, 1.01, 0]}
position={[-0.12, 1.01, 0]}
      // rotation={[Math.PI / 2, 0, 0]}
      rotation={[1.6,0,-0.1]}
      scale={15.89}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_1.geometry}
        material={materials["SVGMat.086"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_2.geometry}
        material={materials["SVGMat.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_3.geometry}
        material={materials["SVGMat.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_4.geometry}
        material={materials["SVGMat.004"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_5.geometry}
        material={materials["SVGMat.005"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_6.geometry}
        material={materials["SVGMat.006"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_7.geometry}
        material={materials["SVGMat.007"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_8.geometry}
        material={materials["SVGMat.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_9.geometry}
        material={materials["SVGMat.009"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_10.geometry}
        material={materials["SVGMat.010"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_11.geometry}
        material={materials["SVGMat.011"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_12.geometry}
        material={materials["SVGMat.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_13.geometry}
        material={materials["SVGMat.013"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_14.geometry}
        material={materials["SVGMat.014"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_15.geometry}
        material={materials["SVGMat.015"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_16.geometry}
        material={materials["SVGMat.016"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_17.geometry}
        material={materials["SVGMat.017"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_18.geometry}
        material={materials["SVGMat.018"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_19.geometry}
        material={materials["SVGMat.019"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_20.geometry}
        material={materials["SVGMat.020"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_21.geometry}
        material={materials["SVGMat.021"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_22.geometry}
        material={materials["SVGMat.022"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_23.geometry}
        material={materials["SVGMat.023"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_24.geometry}
        material={materials["SVGMat.024"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_25.geometry}
        material={materials["SVGMat.025"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_26.geometry}
        material={materials["SVGMat.026"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_27.geometry}
        material={materials["SVGMat.027"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_28.geometry}
        material={materials["SVGMat.028"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_29.geometry}
        material={materials["SVGMat.029"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_30.geometry}
        material={materials["SVGMat.030"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_31.geometry}
        material={materials["SVGMat.031"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_32.geometry}
        material={materials["SVGMat.032"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_33.geometry}
        material={materials["SVGMat.033"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_34.geometry}
        material={materials["SVGMat.034"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_35.geometry}
        material={materials["SVGMat.035"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_36.geometry}
        material={materials["SVGMat.036"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_37.geometry}
        material={materials["SVGMat.037"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_38.geometry}
        material={materials["SVGMat.038"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_39.geometry}
        material={materials["SVGMat.039"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_40.geometry}
        material={materials["SVGMat.040"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_41.geometry}
        material={materials["SVGMat.041"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_42.geometry}
        material={materials["SVGMat.042"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_43.geometry}
        material={materials["SVGMat.043"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_44.geometry}
        material={materials["SVGMat.044"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_45.geometry}
        material={materials["SVGMat.045"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_46.geometry}
        material={materials["SVGMat.046"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_47.geometry}
        material={materials["SVGMat.047"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_48.geometry}
        material={materials["SVGMat.048"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_49.geometry}
        material={materials["SVGMat.049"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_50.geometry}
        material={materials["SVGMat.050"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_51.geometry}
        material={materials["SVGMat.051"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_52.geometry}
        material={materials["SVGMat.052"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_53.geometry}
        material={materials["SVGMat.053"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_54.geometry}
        material={materials["SVGMat.054"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_55.geometry}
        material={materials["SVGMat.055"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_56.geometry}
        material={materials["SVGMat.056"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_57.geometry}
        material={materials["SVGMat.057"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_58.geometry}
        material={materials["SVGMat.058"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_59.geometry}
        material={materials["SVGMat.059"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_60.geometry}
        material={materials["SVGMat.060"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_61.geometry}
        material={materials["SVGMat.061"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_62.geometry}
        material={materials["SVGMat.062"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_63.geometry}
        material={materials["SVGMat.063"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_64.geometry}
        material={materials["SVGMat.064"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_65.geometry}
        material={materials["SVGMat.065"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_66.geometry}
        material={materials["SVGMat.066"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_67.geometry}
        material={materials["SVGMat.067"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_68.geometry}
        material={materials["SVGMat.068"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_69.geometry}
        material={materials["SVGMat.069"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_70.geometry}
        material={materials["SVGMat.070"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_71.geometry}
        material={materials["SVGMat.071"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_72.geometry}
        material={materials["SVGMat.072"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_73.geometry}
        material={materials["SVGMat.073"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_74.geometry}
        material={materials["SVGMat.074"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_75.geometry}
        material={materials["SVGMat.075"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_76.geometry}
        material={materials["SVGMat.076"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_77.geometry}
        material={materials["SVGMat.077"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_78.geometry}
        material={materials["SVGMat.078"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_79.geometry}
        material={materials["SVGMat.079"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_80.geometry}
        material={materials["SVGMat.080"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_81.geometry}
        material={materials["SVGMat.081"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_82.geometry}
        material={materials["SVGMat.082"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_83.geometry}
        material={materials["SVGMat.083"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_84.geometry}
        material={materials["SVGMat.084"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_85.geometry}
        material={materials["SVGMat.085"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_86.geometry}
        material={materials["SVGMat.087"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_87.geometry}
        material={materials["SVGMat.088"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_88.geometry}
        material={materials["SVGMat.089"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_89.geometry}
        material={materials["SVGMat.090"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_90.geometry}
        material={materials["SVGMat.091"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_91.geometry}
        material={materials["SVGMat.092"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_92.geometry}
        material={materials["SVGMat.093"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_93.geometry}
        material={materials["SVGMat.094"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_94.geometry}
        material={materials["SVGMat.095"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_95.geometry}
        material={materials["SVGMat.096"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_96.geometry}
        material={materials["SVGMat.097"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_97.geometry}
        material={materials["SVGMat.098"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_98.geometry}
        material={materials["SVGMat.099"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_99.geometry}
        material={materials["SVGMat.100"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_100.geometry}
        material={materials["SVGMat.101"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_101.geometry}
        material={materials["SVGMat.102"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_102.geometry}
        material={materials["SVGMat.103"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_103.geometry}
        material={materials["SVGMat.104"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_104.geometry}
        material={materials["SVGMat.105"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_105.geometry}
        material={materials["SVGMat.106"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_106.geometry}
        material={materials["SVGMat.107"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_107.geometry}
        material={materials["SVGMat.108"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_108.geometry}
        material={materials["SVGMat.109"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_109.geometry}
        material={materials["SVGMat.110"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_110.geometry}
        material={materials["SVGMat.111"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_111.geometry}
        material={materials["SVGMat.112"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_112.geometry}
        material={materials["SVGMat.113"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_113.geometry}
        material={materials["SVGMat.114"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_114.geometry}
        material={materials["SVGMat.115"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_115.geometry}
        material={materials["SVGMat.116"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_116.geometry}
        material={materials["SVGMat.117"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_117.geometry}
        material={materials["SVGMat.118"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_118.geometry}
        material={materials["SVGMat.119"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_119.geometry}
        material={materials["SVGMat.120"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_120.geometry}
        material={materials["SVGMat.121"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_121.geometry}
        material={materials["SVGMat.122"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_122.geometry}
        material={materials["SVGMat.123"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_123.geometry}
        material={materials["SVGMat.124"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_124.geometry}
        material={materials["SVGMat.125"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_125.geometry}
        material={materials["SVGMat.126"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_126.geometry}
        material={materials["SVGMat.127"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_127.geometry}
        material={materials["SVGMat.128"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_128.geometry}
        material={materials["SVGMat.129"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_129.geometry}
        material={materials["SVGMat.130"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_130.geometry}
        material={materials["SVGMat.131"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_131.geometry}
        material={materials["SVGMat.132"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_132.geometry}
        material={materials["SVGMat.133"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_133.geometry}
        material={materials["SVGMat.134"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_134.geometry}
        material={materials["SVGMat.135"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_135.geometry}
        material={materials["SVGMat.136"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_136.geometry}
        material={materials["SVGMat.137"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_137.geometry}
        material={materials["SVGMat.138"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_138.geometry}
        material={materials["SVGMat.139"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_139.geometry}
        material={materials["SVGMat.140"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_140.geometry}
        material={materials["SVGMat.142"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_141.geometry}
        material={materials["SVGMat.155"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_142.geometry}
        material={materials["SVGMat.156"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_143.geometry}
        material={materials["SVGMat.157"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve085_144.geometry}
        material={materials["SVGMat.158"]}
      />
    </group>
  </group>
  );
}

useGLTF.preload("/assets/newthreedversecapitalDtestone.glb");
