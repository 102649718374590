import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/JoinUs.module.css'
import mystyles from './componentstyles/JoiningButton.module.css'

export default function JoinUs() {
  return (
    <>
    <div className={styles.joinusscreencontainer}>
    <h1 className={styles.newjoinus}>Join Us</h1>
    <h1 className={styles.feeling_excited}>Feeling Excited About Our Journey???? </h1>
    <h1 className={styles.goingallalone}>Going All Alone?</h1>
    <div className={styles.imageholder}>
    {/* <Image src="/assets/memoji_two.png" alt="Vercel Logo"
    height={100}
    width={100} 
    className={styles.tvlogo}
      /> */}
       <h1 className={styles.noooo}>Nooo!</h1>
    </div>
    <h1 className={styles.feeling}>The Feeling Of Going Together on an amazing Journey and experiences Nothing Can Beat It!</h1>
    <h1 className={styles.feeling}>&quot;We are Building Things that we ourselves are proud to use!&quot;</h1>
    <div className={styles.imageholder}>
    {/* <Image src="/assets/memoji.png" alt="Vercel Logo"
    height={300}
    width={300} 
    className={styles.tvlogo}
      /> */}
    </div>
    <h1 className={styles.join_us_on_journey}>Join Us On Our Journey To Build The &quot;The Immersive Internet&quot;</h1>
    <h1 className={styles.come_on_join_us}>&quot;Come on Join Us!&quot;</h1>
    
    <h1 className={styles.come_on_join_us}>Click to Apply</h1>

     <div className={mystyles.joiningbuttonscreencontainer}>
    <div className={mystyles.buttoncenter}>
    <a href="https://forms.gle/75EGyWGebadXqq5L9" className={styles.joinbutton} target="_blank" rel="noopener noreferrer">Join Threed Verse</a>
    </div>
    <h1 className={styles.come_on_join_us}>Or</h1>
    <div className={mystyles.buttoncenter}>
    {/* <a href="https://forms.gle/3hwWVxbnz9QBVVtx7" className={styles.internshipbutton} target="_blank" rel="noopener noreferrer">Look For Internship Opportunity At Threed Verse</a> */}
    <a href="https://forms.gle/3hwWVxbnz9QBVVtx7" className={styles.internshipbutton} target="_blank" rel="noopener noreferrer">Look For Internship</a>
    </div>
    <h1 className={styles.come_on_join_us}>&quot;Push Yourself Because No One Else Is Going To Do It For You!&quot;</h1>
    <h1 className={styles.why_we}>Why We?</h1>
    <h1 className={styles.we_will_succeed_for_sure}>&quot;We will Succeed For Sure ! &quot;</h1>
    <h1 className={styles.maybenotimmediately}>&quot;May be Not Immediately but DEFINITELY &quot;</h1>
    <div className={styles.container}>
      Let&apos;s
  Make 
  <div className={styles.flip}>
    <div><div>wOrK</div></div>
    <div><div>lifeStyle</div></div>
    <div><div>Something</div></div>
    <div><div>Everything</div></div>
    <div><div>Internet</div></div>
  </div>
  {/* AweSoMe! */}
  MAGICAL🪄&AWESOME🤗
</div>
    <h1 className={styles.team_of_winners}>&quot;We are a Team Of Winners&quot;</h1>
    {/* <h1 className={styles.loosers_visualize}>&quot;Loosers Visualize The penalty Of Failure Winners Visualize The Reward Of Success&quot;</h1> */}
    <div className={styles.blockquotewrapper}>
  <div className={styles.blockquote}>
    {/* <h1>
     Intuitive design happens when <span style={{color:"#ffffff"}}>current knowledge</span> is the same as the <span style={{color:"#ffffff"}}>target knowledge.</span>
     </h1> */}
      {/* <h1>
      &quot;Loosers Visualize The penalty Of Failure Winners Visualize The Reward Of Success&quot;
     </h1> */}
     <h1 className={styles.loosers_visualize}>&quot;Loosers Visualize The penalty Of Failure Winners Visualize The Reward Of Success&quot;</h1>
  </div>
</div>
    </div>
    {/* <div className={styles.container}>
  <svg viewBox="0 0 960 300" className={styles.svg}>
    <symbol className={styles.stext}>
      <text text-anchor="middle" x="50%" y="80%">Montserrat</text>
    </symbol>
    <g className = "g-ants">
      <use xlinkHref="#s-text" className={styles.textcopy}></use>
      <use xlinkHref="#s-text"  className={styles.textcopy}></use>
      <use xlinkHref="#s-text"  className={styles.textcopy}></use>
      <use xlinkHref="#s-text"  className={styles.textcopy}></use>
      <use xlinkHref="#s-text"  className={styles.textcopy}></use>
    </g>
  </svg>
</div> */}
    </div>
    </>
  )
}
