// import logo from './logo.svg';
import './App.css';
import styles from './Components/componentstyles/Home.module.css'
import Hero from './Components/Hero'
import Home_Screen from './Components/Home_Screen'
import Footer from './Components/Footer'
import AboutUs from './Components/AboutUs'
import JoinUs   from './Components/JoinUs'
import OurBelief   from './Components/OurBelief'
import  Vision  from './Components/Vison'
import  InvestIn  from './Components/InvestIn'
import FoundersInfo  from './Components/FoundersInfo'
import KnowMore  from './Components/KnowMore'

function App() {
  return (
    <>
    <div className={styles.landingpageholder}>
    <Hero/>
  <Home_Screen/>
 <Vision/>
 <FoundersInfo/>
 <OurBelief/>
 <AboutUs/>
 <JoinUs/>
 <InvestIn/>
 <KnowMore/>
 <Footer/> 
    </div>
     </>
  );
}

export default App;
