import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/Hscreen.module.css'

// export default function Home_Screen() {
//   return (
//     <>
//     <div className={styles.hscreencontainer}>
//         <div className={styles.tvlogocontainer}>
//         <Image src="/assets/Beyond_Exhibitions_BEYOND_MONET___Canada_s_Largest_Immersive_Exp.png" alt="Vercel Logo"
//     height={200}
//     width={600} 
//     //fill
//     className={styles.tvlogo}
//       />
//       <h1 className={styles.welcometo}>We are</h1>
//         </div>
//     </div>
//     </>
//   )
// }

// export default function Home_Screen() {
//   return (
//     <>
//     <div className={styles.hscreencontainer}>
//     <h1 className={styles.weat}>We At</h1>
//     <h1 className={styles.welcometo}>Threed Verse</h1>
//     <h1 className={styles.welcometo}>are</h1>
//     <h1 className={styles.welcometo}>On a Mission To Build</h1>
//     <h1 className={styles.welcometo}>&quotThe Immersive Internet"</h1>
//     </div>
//     </>
//   )
// }


export default function Home_Screen() {
  return (
    <>
    <div className={styles.hscreencontainer}>
    <h1 className={styles.newweat}>We At</h1>
    {/* <h1 className={styles.welcometo}>Threed Verse</h1>
    <h1 className={styles.welcometo}>are</h1>
    <h1 className={styles.onamission}>On a Mission To Build</h1>
    <h1 className={styles.welcometo}>&quot;The Immersive Internet&quot;</h1> */}
    <div className={styles.wrapper}>
  <div className={styles.container}>
    <h1 className={styles.multicolouredhone}>Threed Verse are On a Mission To Build </h1>
    <h1 className={styles.multicolouredhone}>&quot;The Immersive Internet&quot;</h1>
  </div>
</div>
    </div>
    </>
  )
}
