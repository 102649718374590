import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/Vision.module.css'
// import VrHeadset  from './THREEDCOMPONENTS/VrHeadset'
import { lazy,Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
// import { Environment } from '@react-three/drei'

const VrHeadset = lazy(() => import("./THREEDCOMPONENTS/VrHeadset"));

// export default function Vison() {
//   return (
//     <>
//     <div classNameName={styles.visionscreencontainer}>
//     <h1 classNameName={styles.vision}>Vision</h1>
//     <h1 classNameName={styles.welcometo}>&quot;At Threed Verse Our Vision is&quot; </h1>
//     <h1 classNameName={styles.welcometo}>&quot;To Build an Immersive Internet and the set of Next Generation Technology&quot;</h1>
//     <h1 classNameName={styles.welcometo}>That Powers All Of Those Increadible and amazing Experieneces Awaiting Us</h1>
//     <h1 classNameName={styles.welcometo}>&quot;The Immersive Internet&quot;</h1>
//     <h1 classNameName={styles.welcometo}>&quot;We are Gonna Make It!&quot;</h1>
//     <h1 classNameName={styles.welcometo}>&quot;Yes Confident Enough!&quot;</h1>
//     <h1 classNameName={styles.welcometo}>&quot;Actually Very Very Confident&quot;</h1>
//     </div>
//     </>
//   )
// }

// export default function Vison() {
//   return (
//     <>
//     <div classNameName={styles.visionscreencontainer}>
//     <h1 classNameName={styles.vision}>Vision</h1>
//     <h1 classNameName={styles.attv}>&quot;At Threed Verse Our Vision is&quot; </h1>
//     <h1 classNameName={styles.visionqoute}>&quot;To Build an Immersive Internet and the set of Next Generation Technology&quot;</h1>
//     <h1 classNameName={styles.visionqoute}>That Powers All Of Those Increadible and amazing Experiences Awaiting Us</h1>
//     <h1 classNameName={styles.immersiveinternet}>&quot;The Immersive Internet&quot;</h1>
//     <h1 classNameName={styles.wegonnamakeit}>&quot;We are Gonna Make It!&quot;</h1>
//     <h1 classNameName={styles.confidentenough}>&quot;Yes Confident Enough!&quot;</h1>
//     <h1 classNameName={styles.veryveryconfident}>&quot;Actually Very Very Confident&quot;</h1>
//     </div>
//     </>
//   )
// }


export default function Vison() {
  return (
    <>
    <div className={styles.visionscreencontainer}>
    <h1 className={styles.strokedvision}>Vision</h1>
    <h1 className={styles.attv}>&quot;At Threed Verse Our Vision is&quot; </h1>
    <h1 className={styles.visionqoute}>&quot;To Build an Immersive Internet and the set of Next Generation Technology That Powers All Of Those Incredible and amazing Experiences Awaiting Us&quot;</h1>
    {/* <h1 className={styles.visionqoute}>That Powers All Of Those Increadible and amazing Experiences Awaiting Us</h1> */}
    <section className={styles.header}>
  <div className={styles.titlewrapper}>
    <h1 className={styles.sweettitle}>
   <span className={styles.sweet} title='Sweet'>Immersive</span>
    </h1>
    <h1 className={styles.sweettitle}>
   <span className={styles.sweet} title='Sweet'>Internet</span>
    </h1>
    {/* <span className={styles.toptitle}>Get me all the</span>
    <span className={styles.bottomtitle}>You can find</span> */}
    </div>
</section>
{/* <section className={styles.disclaimer}>
  <span>Inspired by <a href="https://www.makesweetbeats.com/">makesweetbeats</a></span>
</section> */}
    {/* <h1 className={styles.immersiveinternet}>&quot;The Immersive Internet&quot;</h1> */}
    <div className={styles.threedtlogocontainer}>
          {/* <Canvas style={{backgroundColor:"black"}}> */}
          <Canvas style={{paddingTop:"0px" ,width: "100vw", height: "50vh"}}>
        <ambientLight intensity={0.1} />
  <directionalLight color="white" position={[0, 0, 5]} />
        <Suspense fallback={null}>
          <VrHeadset />
          {/* <Environment preset="sunset" background /> */}
        </Suspense>
      </Canvas>
          </div>
    <h1 className={styles.wegonnamakeit}>&quot;We are Gonna Make It!&quot;</h1>
    <h1 className={styles.confidentenough}>&quot;Yes Confident Enough!&quot;</h1>
    <h1 className={styles.veryveryconfident}>&quot;Actually Very Very Confident&quot;</h1>
    {/* <h1 className={styles.cubed}>&quot;Actually Very Very Confident&quot;</h1> */}

    </div>
    </>
  )
}
